<template>
  <!--begin::Tracking index-->
  <div class="gray-box">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/envios-primary.svg"
      />
      <div>
        {{ $t("SHIPMENTS.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-6">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("SHIPMENTS.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="downloadExcel"
            :disabled="DownloadingExcel"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("SHIPMENTS.EXCEL_TITLE") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.ORIGIN") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByOriginProvinceID"
            @change="searchShipments(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="'OP' + p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.DESTINATION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDestinationProvinceID"
            @change="searchShipments(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="'DP' + p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.STATUS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByShipmentStatusID"
            @change="searchShipments(false)"
          >
            <option
              v-for="s in StatusOptions"
              :key="'S' + s.ShipmentStatusID"
              :value="s.ShipmentStatusID"
              class="text-primary"
            >
              {{ s.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 pt-3">
          <label v-if="currentUserRoleID === rolesList.ID_OBSERVER">
            {{ $t("SHIPMENTS.SENDER") + " / " + $t("SHIPMENTS.TRANSPORTER") }}
          </label>
          <label v-else>
            {{
              showSenderColumn
                ? $t("SHIPMENTS.SENDER")
                : $t("SHIPMENTS.TRANSPORTER")
            }}
          </label>
          <v-text-field
            class="form-control"
            v-model="FilterByCompanyName"
            @keyup="onSearchTextKeyup"
          />
        </div>

        <div v-if="showDriverColumn" class="col-lg-4 pt-3">
          <label>
            {{ $t("SHIPMENTS.DRIVER") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDriverUserID"
            @change="searchShipments(false)"
          >
            <option
              v-for="d in DriverOptions"
              :key="'D' + d.UserID"
              :value="d.UserID"
              class="text-primary"
            >
              {{ d.Nick }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 pt-10 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::MultiEdit-->
    <div
      v-if="allowMultiEdit && MultiEditItemsChecked.length > 0"
      class="white-box pt-4 pb-3 mb-6 d-flex justify-content-between"
    >
      <span>
        {{ $tc("SHIPMENTS.CHECKED_ITEMS", MultiEditItemsChecked.length) }}
      </span>

      <button
        class="text-uppercase font-weight-boldest"
        @click="openMultiEditDialog"
      >
        <inline-svg src="/media/icons/edit-alt-2-primary.svg" class="mr-3" />
        {{ $t("GENERIC.EDIT") }}
      </button>
    </div>

    <!--begin::MultiEdit dialog-->
    <v-dialog
      v-model="MultiEditDialog.Show"
      transition="dialog-bottom-transition"
      max-width="700px"
    >
      <v-card>
        <div class="bg-primary text-white font-size-h5 py-3 px-7">
          {{ $t("GENERIC.EDIT_ITEMS") }}
        </div>

        <v-card-text class="pt-6 pb-3">
          <v-form
            ref="formMultiEditDialog"
            v-model="MultiEditDialog.Valid"
            lazy-validation
            class="form"
          >
            <!--begin::There/Back selector-->
            <div class="row mb-6">
              <button
                v-if="MultiEditDialog.HasThere"
                class="btn btn-pill button-form mr-3"
                :class="
                  MultiEditDialog.ShowThere
                    ? 'btn-primary'
                    : 'btn-white button-form-primary'
                "
                @click="toggleMultiEditDialog('There')"
              >
                {{ $t("SHIPMENT_LEG_TYPES_BY_ID.1") }}
              </button>
              <button
                v-if="MultiEditDialog.HasBack"
                class="btn btn-pill button-form"
                :class="
                  MultiEditDialog.ShowBack
                    ? 'btn-primary'
                    : 'btn-white button-form-primary'
                "
                @click="toggleMultiEditDialog('Back')"
              >
                {{ $t("SHIPMENT_LEG_TYPES_BY_ID.2") }}
              </button>
            </div>
            <!--end::There/Back selector-->

            <div v-for="whichLeg in ['There', 'Back']" :key="whichLeg">
              <div v-if="MultiEditDialog['Show' + whichLeg]" class="row">
                <!--begin::There/Back driver-->
                <div class="form-group col-lg-6">
                  <label>
                    <inline-svg
                      src="/media/icons/choferes-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("SHIPMENTS.DRIVER") }}
                  </label>
                  <select
                    class="form-control select-down"
                    v-model="MultiEditDialog.Fields[whichLeg].DriverUserID"
                  >
                    <option
                      v-for="d in DriverOptionsForMultiEdit"
                      :key="d.UserID"
                      :value="d.UserID"
                      class="text-primary"
                    >
                      {{ d.Nick }}
                    </option>
                  </select>
                  <button
                    v-if="MultiEditDialog.Fields[whichLeg].DriverUserID > 0"
                    @click="MultiEditDialog.Fields[whichLeg].DriverUserID = 0"
                    class="mt-2"
                  >
                    <inline-svg
                      src="/media/icons/undo-primary.svg"
                      class="mr-1"
                    />
                    {{ $t("GENERIC.UNDO_CHANGES") }}
                  </button>
                </div>
                <!--end::There/Back driver-->
              </div>

              <div v-if="MultiEditDialog['Show' + whichLeg]" class="row">
                <!--begin::There/Back OriginDatePlanned-->
                <div class="form-group col-lg-6">
                  <label>
                    <inline-svg
                      src="/media/icons/calendar-alt-1-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("OFFERS.COMMON.ORIGIN_DATE_PLANNED") }}
                  </label>
                  <v-text-field
                    class="form-control bg-white"
                    :value="
                      MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo
                        .length > 0
                        ? formatDateRange(
                            MultiEditDialog.Fields[whichLeg]
                              .OriginDatePlannedFrom,
                            MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo
                          )
                        : '--- ' + $t('GENERIC.MULTIPLE_VALUES') + ' ---'
                    "
                    @click="openOriginDatePlannedDialog(whichLeg)"
                    readonly
                  />
                  <button
                    v-if="
                      MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo
                        .length > 0
                    "
                    @click="resetOriginDatePlannedDialog"
                    class="mt-2"
                  >
                    <inline-svg
                      src="/media/icons/undo-primary.svg"
                      class="mr-1"
                    />
                    {{ $t("GENERIC.UNDO_CHANGES") }}
                  </button>

                  <!--begin::OriginDatePlanned dialog-->
                  <v-dialog
                    v-model="OriginDatePlannedDialog.Show"
                    :return-value="OriginDatePlannedDialog.Value"
                    persistent
                    width="320px"
                  >
                    <v-date-picker
                      id="OriginDatePlannedDialog"
                      v-model="OriginDatePlannedDialog.Value"
                      color="tertiary"
                      scrollable
                      range
                      :locale="DateLocale"
                      :first-day-of-week="DateFirstDayOfWeek"
                      :max="OneYear"
                    >
                      <div class="row button-row">
                        <button
                          class="btn btn-pill btn-white button-form button-form-primary mr-9"
                          style="min-width: 125px"
                          @click="cancelOriginDatePlannedDialog"
                        >
                          {{ $t("GENERIC.CANCEL") }}
                        </button>
                        <button
                          class="btn btn-pill btn-tertiary button-form"
                          style="min-width: 125px"
                          @click="saveOriginDatePlannedDialog"
                        >
                          {{ $t("GENERIC.OK") }}
                        </button>
                      </div>
                    </v-date-picker>
                  </v-dialog>
                  <!--end::OriginDatePlanned dialog-->
                </div>
                <!--end::There/Back OriginDatePlanned-->

                <!--begin::There/Back DestinationDatePlanned-->
                <div class="form-group col-lg-6">
                  <label>
                    <inline-svg
                      src="/media/icons/calendar-alt-1-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("OFFERS.COMMON.DESTINATION_DATE_PLANNED") }}
                  </label>
                  <v-text-field
                    class="form-control bg-white"
                    :value="
                      MultiEditDialog.Fields[whichLeg].DestinationDatePlannedTo
                        .length > 0
                        ? formatDateRange(
                            MultiEditDialog.Fields[whichLeg]
                              .DestinationDatePlannedFrom,
                            MultiEditDialog.Fields[whichLeg]
                              .DestinationDatePlannedTo
                          )
                        : '--- ' + $t('GENERIC.MULTIPLE_VALUES') + ' ---'
                    "
                    @click="openDestinationDatePlannedDialog(whichLeg)"
                    readonly
                  />
                  <button
                    v-if="
                      MultiEditDialog.Fields[whichLeg].DestinationDatePlannedTo
                        .length > 0
                    "
                    @click="resetDestinationDatePlannedDialog"
                    class="mt-2"
                  >
                    <inline-svg
                      src="/media/icons/undo-primary.svg"
                      class="mr-1"
                    />
                    {{ $t("GENERIC.UNDO_CHANGES") }}
                  </button>

                  <!--begin::DestinationDatePlanned dialog-->
                  <v-dialog
                    v-model="DestinationDatePlannedDialog.Show"
                    :return-value="DestinationDatePlannedDialog.Value"
                    persistent
                    width="320px"
                  >
                    <v-date-picker
                      id="DestinationDatePlannedDialog"
                      v-model="DestinationDatePlannedDialog.Value"
                      color="tertiary"
                      scrollable
                      range
                      :locale="DateLocale"
                      :first-day-of-week="DateFirstDayOfWeek"
                      :min="MultiEditDialog.Fields[whichLeg].OriginDatePlanned"
                      :max="OneYear"
                    >
                      <div class="row button-row">
                        <button
                          class="btn btn-pill btn-white button-form button-form-primary mr-9"
                          style="min-width: 125px"
                          @click="cancelDestinationDatePlannedDialog"
                        >
                          {{ $t("GENERIC.CANCEL") }}
                        </button>
                        <button
                          class="btn btn-pill btn-tertiary button-form"
                          style="min-width: 125px"
                          @click="saveDestinationDatePlannedDialog"
                        >
                          {{ $t("GENERIC.OK") }}
                        </button>
                      </div>
                    </v-date-picker>
                  </v-dialog>
                  <!--end::DestinationDatePlanned dialog-->
                </div>
                <!--end::There/Back DestinationDatePlanned-->

                <div
                  class="col-lg-12 my-3 border-top-dark"
                  style="border-top: 2px solid"
                >
                  &nbsp;
                </div>

                <!--begin::There/Back OriginDateDone-->
                <div class="form-group col-lg-6">
                  <label>
                    <inline-svg
                      src="/media/icons/calendar-alt-1-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("SHIPMENTS.ORIGIN_DATE_DONE") }}
                  </label>
                  <v-text-field
                    class="form-control bg-white"
                    :value="
                      MultiEditDialog.Fields[whichLeg].OriginDateDone.length > 0
                        ? formatDate(
                            MultiEditDialog.Fields[whichLeg].OriginDateDone
                          )
                        : '--- ' + $t('GENERIC.MULTIPLE_VALUES') + ' ---'
                    "
                    @click="openOriginDateDoneDialog(whichLeg)"
                    readonly
                  />
                  <button
                    v-if="
                      MultiEditDialog.Fields[whichLeg].OriginDateDone.length > 0
                    "
                    @click="resetOriginDateDoneDialog"
                    class="mt-2"
                  >
                    <inline-svg
                      src="/media/icons/undo-primary.svg"
                      class="mr-1"
                    />
                    {{ $t("GENERIC.UNDO_CHANGES") }}
                  </button>

                  <!--begin::OriginDateDone dialog-->
                  <v-dialog
                    v-model="OriginDateDoneDialog.Show"
                    :return-value="OriginDateDoneDialog.Value"
                    persistent
                    width="320px"
                  >
                    <v-date-picker
                      v-model="OriginDateDoneDialog.Value"
                      color="tertiary"
                      scrollable
                      :locale="DateLocale"
                      :first-day-of-week="DateFirstDayOfWeek"
                      :min="OneYearAgo"
                      :max="Today"
                    >
                      <div class="row button-row">
                        <button
                          class="btn btn-pill btn-white button-form button-form-primary mr-9"
                          style="min-width: 125px"
                          @click="cancelOriginDateDoneDialog"
                        >
                          {{ $t("GENERIC.CANCEL") }}
                        </button>
                        <button
                          class="btn btn-pill btn-tertiary button-form"
                          style="min-width: 125px"
                          @click="saveOriginDateDoneDialog"
                        >
                          {{ $t("GENERIC.OK") }}
                        </button>
                      </div>
                    </v-date-picker>
                  </v-dialog>
                  <!--end::OriginDateDone dialog-->
                </div>
                <!--end::There/Back OriginDateDone-->

                <!--begin::There/Back DestinationDateDone-->
                <div class="form-group col-lg-6">
                  <label>
                    <inline-svg
                      src="/media/icons/calendar-alt-1-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("SHIPMENTS.DESTINATION_DATE_DONE") }}
                  </label>
                  <v-text-field
                    class="form-control bg-white"
                    :value="
                      MultiEditDialog.Fields[whichLeg].DestinationDateDone
                        .length > 0
                        ? formatDate(
                            MultiEditDialog.Fields[whichLeg].DestinationDateDone
                          )
                        : '--- ' + $t('GENERIC.MULTIPLE_VALUES') + ' ---'
                    "
                    @click="openDestinationDateDoneDialog(whichLeg)"
                    readonly
                  />
                  <button
                    v-if="
                      MultiEditDialog.Fields[whichLeg].DestinationDateDone
                        .length > 0
                    "
                    @click="resetDestinationDateDoneDialog"
                    class="mt-2"
                  >
                    <inline-svg
                      src="/media/icons/undo-primary.svg"
                      class="mr-1"
                    />
                    {{ $t("GENERIC.UNDO_CHANGES") }}
                  </button>

                  <!--begin::DestinationDateDone dialog-->
                  <v-dialog
                    v-model="DestinationDateDoneDialog.Show"
                    :return-value="DestinationDateDoneDialog.Value"
                    persistent
                    width="320px"
                  >
                    <v-date-picker
                      v-model="DestinationDateDoneDialog.Value"
                      color="tertiary"
                      scrollable
                      :locale="DateLocale"
                      :first-day-of-week="DateFirstDayOfWeek"
                      :min="OneYearAgo"
                      :max="Today"
                    >
                      <div class="row button-row">
                        <button
                          class="btn btn-pill btn-white button-form button-form-primary mr-9"
                          style="min-width: 125px"
                          @click="cancelDestinationDateDoneDialog"
                        >
                          {{ $t("GENERIC.CANCEL") }}
                        </button>
                        <button
                          class="btn btn-pill btn-tertiary button-form"
                          style="min-width: 125px"
                          @click="saveDestinationDateDoneDialog"
                        >
                          {{ $t("GENERIC.OK") }}
                        </button>
                      </div>
                    </v-date-picker>
                  </v-dialog>
                  <!--end::DestinationDateDone dialog-->
                </div>
                <!--end::There/Back DestinationDateDone-->
              </div>
            </div>

            <div class="row button-row py-3">
              <button
                class="btn btn-pill btn-white button-form button-form-primary mr-9"
                @click="cancelMultiEditDialog"
              >
                {{ $t("GENERIC.CANCEL") }}
              </button>
              <button
                class="btn btn-pill btn-tertiary button-form"
                :disabled="!MultiEditDialog.Valid"
                @click="saveMultiEditDialog"
              >
                {{ $t("GENERIC.OK") }}
              </button>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end::MultiEdit dialog-->
    <!--end::MultiEdit-->

    <!--begin::Table Results-->
    <v-data-table
      id="TrackingsTable"
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-if="allowMultiEdit" v-slot:header.MultiCheckbox="{ header }">
        <br />
        <v-checkbox
          v-model="MultiEditItemsCheckedAll"
          @click="onMultiEditItemsCheckedAll"
        />
      </template>

      <template v-slot:item="{ item }">
        <tr :class="item.ShipmentStatusID > 11 ? 'bg-primary-o-30' : ''">
          <td v-if="allowMultiEdit" class="pt-6">
            <v-checkbox
              v-model="MultiEditItemsChecked"
              :value="item.ShipmentLegVehicleID"
            />
          </td>

          <td class="pt-3">
            <span
              class="action-icon"
              :class="[item.AlertCountOther > 0 ? 'border-danger' : '']"
              @click="
                $router.push(
                  '/manager/shipments/edit/' +
                    item.ShipmentLegVehicleID +
                    '?ActiveTab=planning'
                )
              "
            >
              <inline-svg
                :src="
                  '/media/icons/' +
                    (currentUserRoleID === rolesList.ID_OBSERVER ||
                    item.ShipmentStatusID >= 10
                      ? 'view-'
                      : 'edit-alt-2-') +
                    (item.AlertCountOther > 0 ? 'danger' : 'primary') +
                    '.svg'
                "
              />
            </span>

            <span
              class="action-icon ml-3"
              :class="[item.AlertCountChat > 0 ? 'border-danger' : '']"
              @click="
                $router.push(
                  '/manager/shipments/edit/' +
                    item.ShipmentLegVehicleID +
                    '?ActiveTab=messages'
                )
              "
            >
              <inline-svg
                :src="
                  '/media/icons/chat-' +
                    (item.AlertCountChat > 0 ? 'danger' : 'primary') +
                    '.svg'
                "
              />
            </span>

            <span
              v-if="item.TotalCountIncident > 0"
              class="action-icon ml-3"
              :class="[item.AlertCountIncident > 0 ? 'border-danger' : '']"
              @click="
                $router.push(
                  '/manager/shipments/edit/' +
                    item.ShipmentLegVehicleID +
                    '?ActiveTab=incidents'
                )
              "
            >
              <inline-svg
                :src="
                  '/media/icons/alerts-alt-2-' +
                    (item.AlertCountIncident > 0 ? 'danger' : 'primary') +
                    '.svg'
                "
              />
            </span>
          </td>

          <td>
            <span
              v-if="
                item.AlertCountChat +
                  item.AlertCountOther +
                  item.AlertCountDocument +
                  item.AlertCountIncident ===
                  0
              "
            >
              {{ item.ShipmentID }}
            </span>
            <v-badge v-else left inline color="bg-danger text-white">
              <template v-slot:badge>
                {{
                  item.AlertCountChat +
                    item.AlertCountOther +
                    item.AlertCountDocument +
                    item.AlertCountIncident
                }}
              </template>
              <span class="ml-1">{{ item.ShipmentID }}</span>
            </v-badge>
          </td>

          <td class="pt-4">
            <span
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              :class="
                formatStatusClass(item.ShipmentStatusID, item.IncidentCount)
              "
              style="white-space: nowrap"
            >
              {{
                formatStatusName(item.ShipmentStatusID, item.TotalCountIncident)
              }}
            </span>
          </td>

          <td>
            {{ formatDate(item.CreatedAt) }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{
                item.Vehicle.Brand +
                  ", " +
                  item.Vehicle.Model +
                  ", " +
                  item.Vehicle.Version +
                  ", " +
                  item.Vehicle.Year +
                  ". " +
                  item.Vehicle.VehicleTypeName +
                  ", " +
                  item.Vehicle.VehicleStatusName +
                  "."
              }}
            </strong>
            <br />
            {{ item.Vehicle.Plate }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.OriginAddress.TradeName }}
            </strong>
            <br />
            {{ item.OriginAddress.Street }}
            <br />
            {{
              item.OriginAddress.PostalCode + " - " + item.OriginAddress.City
            }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.DestinationAddress.TradeName }}
            </strong>
            <br />
            {{ item.DestinationAddress.Street }}
            <br />
            {{
              item.DestinationAddress.PostalCode +
                " - " +
                item.DestinationAddress.City
            }}
          </td>

          <td>
            {{ $t("SHIPMENT_LEG_TYPES_BY_ID." + item.ShipmentLegTypeID) }}
          </td>

          <td v-if="showSenderColumn">
            {{ item.SenderName }}
          </td>

          <td v-if="showTransporterColumn">
            {{ item.TransporterName }}
          </td>

          <td>
            {{
              formatOriginDateDesired(
                item.ShipmentLegTypeID,
                item.OriginDateDesiredFrom
              )
            }}
          </td>

          <td>
            {{
              formatDateRange(
                item.DestinationDatePlannedFrom,
                item.DestinationDatePlannedTo
              )
            }}
          </td>

          <td v-if="showDriverColumn">
            {{ item.DriverName }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchShipments(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::Tracking index-->
</template>

<style scoped>
#TrackingsTable thead th {
  white-space: nowrap !important;
}
#TrackingsTable tbody td {
  vertical-align: top !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import ShipmentStatusService from "@/core/services/api/v2/shipmentstatus.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "TrackingIndex",
  data() {
    return {
      TableHeaders: [],
      options: {},
      ProvinceOptions: [],
      StatusOptions: [],
      DriverOptions: [],
      DriverOptionsForMultiEdit: [],
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYear: "",
      OneYearAgo: "",
      SortBy: "ShipmentID",
      SortDesc: true,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByOriginProvinceID: 0,
      FilterByDestinationProvinceID: 0,
      FilterByShipmentStatusID: 0,
      FilterByCompanyName: "",
      FilterByLegTypeID: 0,
      FilterByDriverUserID: 0,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ],
      DownloadingExcel: false,
      MultiEditItemsCheckedAll: false,
      MultiEditItemsChecked: [],
      MultiEditDialog: {
        Show: false,
        Valid: true,
        HasThere: false,
        HasBack: false,
        ShowThere: false,
        ShowBack: false,
        Fields: {
          ShipmentLegVehicleID: [],
          There: {},
          Back: {}
        },
        DefaultFields: {
          DriverUserID: 0,
          OriginDatePlannedFrom: "",
          OriginDatePlannedTo: "",
          OriginDateDone: "",
          DestinationDatePlannedFrom: "",
          DestinationDatePlannedTo: "",
          DestinationDateDone: ""
        }
      },
      OriginDatePlannedDialog: {
        WhichLeg: "",
        Show: false,
        Value: []
      },
      DestinationDatePlannedDialog: {
        WhichLeg: "",
        Show: false,
        Value: []
      },
      OriginDateDoneDialog: {
        WhichLeg: "",
        Show: false,
        Value: []
      },
      DestinationDateDoneDialog: {
        WhichLeg: "",
        Show: false,
        Value: []
      }
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"]),

    showSenderColumn() {
      return (
        this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN ||
        this.currentUserRoleID === this.rolesList.ID_OBSERVER
      );
    },

    showTransporterColumn() {
      return (
        this.currentUserRoleID === this.rolesList.ID_SENDER_ADMIN ||
        this.currentUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE ||
        this.currentUserRoleID === this.rolesList.ID_OBSERVER
      );
    },

    showDriverColumn() {
      return this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN;
    },

    allowMultiEdit() {
      return this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN;
    }
  },

  created() {
    // Pick format for dates
    this.Today = moment().format(this.DateFormatEN);
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }

    this.OneYear = moment()
      .add(1, "years")
      .format(this.DateFormatEN);
    this.OneYearAgo = moment()
      .subtract(1, "years")
      .format(this.DateFormatEN);

    // Pick visible table columns, by user role
    let visibleColumns = [];

    if (this.allowMultiEdit) {
      visibleColumns.push({
        text: " ",
        value: "MultiCheckbox",
        sortable: false
      });
    }

    visibleColumns.push({
      text: this.$t("GENERIC.ACTIONS"),
      width: 170,
      sortable: false
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.ID"),
      value: "ShipmentID",
      width: 120
    });

    visibleColumns.push({
      text: this.$t("OFFERS.COMMON.STATUS"),
      value: "ShipmentStatusID",
      width: 120
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.CREATION_DATE"),
      value: "CreatedAt",
      width: 130
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.VEHICLE"),
      value: "Vehicle"
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.ORIGIN"),
      value: "OriginAddress",
      width: 180
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.DESTINATION"),
      value: "DestinationAddress",
      width: 180
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.PATH"),
      value: "ShipmentLegTypeID",
      width: 130
    });

    if (this.showSenderColumn) {
      visibleColumns.push({
        text: this.$t("SHIPMENTS.SENDER"),
        value: "SenderName",
        width: 150
      });
    }

    if (this.showTransporterColumn) {
      visibleColumns.push({
        text: this.$t("SHIPMENTS.TRANSPORTER"),
        value: "TransporterName",
        width: 180
      });
    }

    visibleColumns.push({
      text: this.$t("SHIPMENTS.ORIGIN_DATE_DESIRED"),
      value: "OriginDateDesired",
      width: 120
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.DESTINATION_DATE"),
      value: "DestinationDatePlannedFrom",
      width: 120
    });

    if (this.showDriverColumn) {
      visibleColumns.push({
        text: this.$t("SHIPMENTS.DRIVER"),
        value: "DriverName",
        width: 150
      });
    }

    this.TableHeaders = visibleColumns;
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    if (this.showDriverColumn) {
      this.loadDriverOptions();
    }

    this.loadStatusOptions();
    this.loadProvinceOptions();
  },

  methods: {
    loadDriverOptions() {
      this.DriverOptions = [];
      this.DriverOptions.push({ UserID: 0, Nick: " " });
      this.DriverOptionsForMultiEdit = [];
      this.DriverOptionsForMultiEdit.push({
        UserID: 0,
        Nick: "--- " + this.$t("GENERIC.MULTIPLE_VALUES") + " ---"
      });

      UserService.searchDrivers("", "", "Nick", false, 99, 0).then(
        rawResponse => {
          if (rawResponse.response.Users) {
            rawResponse.response.Users.forEach(u => {
              this.DriverOptions.push({ UserID: u.UserID, Nick: u.Nick });
              this.DriverOptionsForMultiEdit.push({
                UserID: u.UserID,
                Nick: u.Nick
              });
            });
          }
        }
      );
    },

    loadStatusOptions() {
      this.StatusOptions = [];
      this.StatusOptions.push({ ShipmentStatusID: 0, Name: " " });

      ShipmentStatusService.listAtTracking().then(response => {
        response.forEach(s => {
          this.StatusOptions.push(s);
        });

        // Special option "any with incidents"
        this.StatusOptions.push({
          ShipmentStatusID: -1,
          Name: this.$t("SHIPMENTS.WITH_INCIDENTS_2")
        });
      });
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchShipments(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(ShipmentService.getCachedFiltersAtTracking()).forEach(
        f => {
          this["FilterBy" + f[0]] = f[1];

          if (!anyFilterNotEmpty) {
            anyFilterNotEmpty = parseInt(f[1]) > 0;
          }
        }
      );

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchShipments(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      ShipmentService.searchAtTracking(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByShipmentStatusID,
        this.FilterByCompanyName,
        this.FilterByLegTypeID,
        this.FilterByDriverUserID,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.ShipmentLegVehicles;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchShipments(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchShipments(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchShipments(false);
    },

    changeItemsCurrentPage() {
      this.searchShipments(true);
    },

    cleanFilters() {
      this.FilterByOriginProvinceID = 0;
      this.FilterByDestinationProvinceID = 0;
      this.FilterByShipmentStatusID = 0;
      this.FilterByCompanyName = "";
      this.FilterByLegTypeID = 0;
      this.ShowFilters = false;
      this.searchShipments(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchShipments(false);
      }, 600);
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },

    formatOriginDateDesired(whichLegTypeID, date) {
      // Special for Back leg
      if (whichLegTypeID === 2 && date === "0001-01-01") {
        return this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED_BACK_THERE");
      } else {
        return this.formatDate(date);
      }
    },

    formatDateRange(dateFrom, dateTo) {
      if (dateFrom === "" && dateTo === "") {
        return this.$t("GENERIC.N_D");
      } else if (dateFrom === dateTo) {
        return this.formatDate(dateFrom);
      } else {
        return this.formatDate(dateFrom) + " - " + this.formatDate(dateTo);
      }
    },

    formatStatusName(statusID, incidentCount) {
      let name = this.$t("SHIPMENT_STATUS_NAMES_BY_ID." + statusID);
      if (incidentCount > 0) {
        name += ", " + this.$t("SHIPMENTS.WITH_INCIDENTS_1");
      }

      return name;
    },

    formatStatusClass(statusID, incidentCount) {
      if (statusID == 12 || incidentCount > 0) {
        // It's cancelled or incidented
        return "text-danger border-danger";
      } else if (statusID == 13) {
        // It's successfully finished
        return "text-success border-success";
      } else {
        return "";
      }
    },

    downloadExcel() {
      // Disable download button, because this may take a while
      this.DownloadingExcel = true;

      ShipmentService.downloadExcelAtTracking(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByShipmentStatusID,
        this.FilterByCompanyName,
        this.FilterByLegTypeID,
        this.FilterByDriverUserID,
        this.SortBy,
        this.SortDesc
      ).then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t("SHIPMENTS.DOWNLOAD_NAME");
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.DownloadingExcel = false;
      });
    },

    onMultiEditItemsCheckedAll(e) {
      e.preventDefault();
      if (this.MultiEditItemsCheckedAll) {
        this.ItemsList.forEach(i => {
          if (!this.MultiEditItemsChecked.includes(i.ShipmentLegVehicleID)) {
            this.MultiEditItemsChecked.push(i.ShipmentLegVehicleID);
          }
        });
      } else {
        this.MultiEditItemsChecked = [];
      }
    },

    openMultiEditDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Pick items, There or Back
        this.MultiEditDialog.Fields.ShipmentLegVehicleID = [];
        this.MultiEditDialog.HasThere = this.MultiEditDialog.HasBack = false;

        let itemCount = this.ItemsList.length;
        this.MultiEditItemsChecked.forEach(i => {
          for (let j = 0; j < itemCount; j++) {
            let maybeItem = this.ItemsList[j];
            if (maybeItem.ShipmentLegVehicleID === i) {
              this.MultiEditDialog.Fields.ShipmentLegVehicleID.push(i);

              if (maybeItem.ShipmentLegTypeID === 1) {
                this.MultiEditDialog.HasThere = true;
              } else {
                this.MultiEditDialog.HasBack = true;
              }

              break;
            }
          }
        });

        this.MultiEditDialog.Fields.There = this.MultiEditDialog.Fields.Back = {};
        this.MultiEditDialog.ShowThere = this.MultiEditDialog.ShowBack = false;

        if (this.MultiEditDialog.HasThere) {
          this.MultiEditDialog.Fields.There = Object.assign(
            {},
            this.MultiEditDialog.DefaultFields
          );

          this.MultiEditDialog.ShowThere = true;
        }

        if (this.MultiEditDialog.HasBack) {
          this.MultiEditDialog.Fields.Back = Object.assign(
            {},
            this.MultiEditDialog.DefaultFields
          );

          this.MultiEditDialog.ShowBack = !this.MultiEditDialog.ShowThere;
        }

        this.MultiEditDialog.Show = true;
      }
    },

    toggleMultiEditDialog(whichLeg) {
      if (whichLeg === "There" && this.MultiEditDialog.HasThere) {
        this.MultiEditDialog.ShowThere = true;
        this.MultiEditDialog.ShowBack = false;
      }

      if (whichLeg === "Back" && this.MultiEditDialog.HasBack) {
        this.MultiEditDialog.ShowThere = false;
        this.MultiEditDialog.ShowBack = true;
      }
    },

    cancelMultiEditDialog() {
      this.MultiEditDialog.Show = false;
      this.MultiEditDialog.Fields.ShipmentLegVehicleID = [];
      this.MultiEditDialog.Fields.There = this.MultiEditDialog.Fields.Back = {};
      this.MultiEditDialog.HasThere = this.MultiEditDialog.HasBack = false;
      this.MultiEditDialog.ShowThere = this.MultiEditDialog.ShowBack = false;
    },

    saveMultiEditDialog(e) {
      e.preventDefault();

      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Disable submit button
        this.MultiEditDialog.Valid = false;

        // Send request:
        ShipmentService.updateMultipleAtTracking(
          this.MultiEditDialog.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            } else if (statusCode === 409) {
              whyError = this.$i18n.t("VALIDATION.INVALID_DATES");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });

            // Search again, because maybe Driver or ShipmentStatus changed
            this.MultiEditItemsChecked = [];
            this.MultiEditItemsCheckedAll = false;
            this.cancelMultiEditDialog();
            this.searchShipments(false);
          }

          // Reset submit button
          this.MultiEditDialog.Valid = true;
        });
      }
    },

    openOriginDatePlannedDialog(whichLeg) {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let oldValue = [
          this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedFrom,
          this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo
        ];

        this.OriginDatePlannedDialog.Value = oldValue;

        this.OriginDatePlannedDialog.WhichLeg = whichLeg;
        this.OriginDatePlannedDialog.Show = true;
      }
    },

    saveOriginDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        if (this.OriginDatePlannedDialog.Value.length === 2) {
          let whichLeg = this.OriginDatePlannedDialog.WhichLeg;
          let newValues = this.OriginDatePlannedDialog.Value.sort();
          this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedFrom =
            newValues[0];
          this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo =
            newValues[1];
        }

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    cancelOriginDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Reset picker to old date
        let whichLeg = this.OriginDatePlannedDialog.WhichLeg;
        this.OriginDatePlannedDialog.Value = [
          this.MultiEditDialog.Fields[whichLeg].OriginDateDesiredFrom,
          this.MultiEditDialog.Fields[whichLeg].OriginDateDesiredTo
        ];

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    resetOriginDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.OriginDatePlannedDialog.WhichLeg;
        this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedFrom = "";
        this.MultiEditDialog.Fields[whichLeg].OriginDatePlannedTo = "";
      }
    },

    openDestinationDatePlannedDialog(whichLeg) {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let oldValue = [
          this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedFrom,
          this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedTo
        ];

        this.DestinationDatePlannedDialog.Value = oldValue;

        this.DestinationDatePlannedDialog.WhichLeg = whichLeg;
        this.DestinationDatePlannedDialog.Show = true;
      }
    },

    saveDestinationDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        if (this.DestinationDatePlannedDialog.Value.length === 2) {
          let whichLeg = this.DestinationDatePlannedDialog.WhichLeg;
          let newValues = this.DestinationDatePlannedDialog.Value.sort();
          this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedFrom =
            newValues[0];
          this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedTo =
            newValues[1];
        }

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    cancelDestinationDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Reset picker to old date
        let whichLeg = this.DestinationDatePlannedDialog.WhichLeg;
        this.DestinationDatePlannedDialog.Value = [
          this.MultiEditDialog.Fields[whichLeg].DestinationDateDesiredFrom,
          this.MultiEditDialog.Fields[whichLeg].DestinationDateDesiredTo
        ];

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    resetDestinationDatePlannedDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.DestinationDatePlannedDialog.WhichLeg;
        this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedFrom = "";
        this.MultiEditDialog.Fields[whichLeg].DestinationDatePlannedTo = "";
      }
    },

    openOriginDateDoneDialog(whichLeg) {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let oldValue = this.MultiEditDialog.Fields[whichLeg].OriginDateDone;
        if (oldValue.length > 0) {
          // Remove the fake time set by saveOriginDateDoneDialog
          oldValue = moment(oldValue).format(this.DateFormatEN);
        }

        this.OriginDateDoneDialog.Value = oldValue;
        this.OriginDateDoneDialog.WhichLeg = whichLeg;
        this.OriginDateDoneDialog.Show = true;
      }
    },

    saveOriginDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.OriginDateDoneDialog.WhichLeg;
        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        this.MultiEditDialog.Fields[whichLeg].OriginDateDone =
          this.OriginDateDoneDialog.Value + " 00:00:00";

        this.OriginDateDoneDialog.Show = false;
      }
    },

    cancelOriginDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Reset picker to old date
        let whichLeg = this.OriginDateDoneDialog.WhichLeg;
        this.OriginDateDoneDialog.Value = this.MultiEditDialog.Fields[
          whichLeg
        ].OriginDateDone;

        this.OriginDateDoneDialog.Show = false;
      }
    },

    resetOriginDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.OriginDateDoneDialog.WhichLeg;
        this.MultiEditDialog.Fields[whichLeg].OriginDateDone = "";
      }
    },

    openDestinationDateDoneDialog(whichLeg) {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let oldValue = this.MultiEditDialog.Fields[whichLeg]
          .DestinationDateDone;

        if (oldValue.length > 0) {
          // Remove the fake time set by saveDestinationDateDoneDialog
          oldValue = moment(oldValue).format(this.DateFormatEN);
        }

        this.DestinationDateDoneDialog.Value = oldValue;
        this.DestinationDateDoneDialog.WhichLeg = whichLeg;
        this.DestinationDateDoneDialog.Show = true;
      }
    },

    saveDestinationDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.DestinationDateDoneDialog.WhichLeg;
        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        this.MultiEditDialog.Fields[whichLeg].DestinationDateDone =
          this.DestinationDateDoneDialog.Value + " 00:00:00";

        this.DestinationDateDoneDialog.Show = false;
      }
    },

    cancelDestinationDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        // Reset picker to old date
        let whichLeg = this.DestinationDateDoneDialog.WhichLeg;
        this.DestinationDateDoneDialog.Value = this.MultiEditDialog.Fields[
          whichLeg
        ].DestinationDateDone;

        this.DestinationDateDoneDialog.Show = false;
      }
    },

    resetDestinationDateDoneDialog() {
      if (this.allowMultiEdit && this.MultiEditItemsChecked.length > 0) {
        let whichLeg = this.DestinationDateDoneDialog.WhichLeg;
        this.MultiEditDialog.Fields[whichLeg].DestinationDateDone = "";
      }
    }
  }
};
</script>
